import { OfferType } from '../api/graphql/fragments/offers';
import { RewardType } from '../api/graphql/fragments/rewards';
import { LegalUpdateType } from '../api/graphql/fragments/legalUpdates';
import { LocalizedTexts, LocalizedFormat, formatCurrencyAmountWithLocaleFormat } from './utils';
import { FINGER_POINTING_UP_EMOJI } from '../components/onboarding/mobileSafariExtension/Styles';

export const frFormat: LocalizedFormat = {
    number: {
        format: {
            delimiter: ' ',
            separator: ',',
        },
    },
    currency: {
        symbol: '€',
        shouldSymbolComeInFront: false,
        separator: ' ',
    },
};

export const frTexts: LocalizedTexts = {
    landing: {
        title: 'Joko : cashback, promos et bons plans - Inscription gratuite',
        header: {
            alreadySignedUp: `Vous avez déjà un compte ?`,
            signIn: `Se connecter`,
            presentation: {
                title: `Gagnez de l'argent sur vos\xa0dépenses`,
                subtitle: `Inscrivez-vous gratuitement sur Joko pour gagner du cashback sur plus de 1000 marques`,
                moreMerchants: `et beaucoup d'autres ...`,
            },
            signUp: {
                title: `Créez votre compte\nJoko gratuitement`,
                withFacebookButton: `S'INSCRIRE AVEC FACEBOOK`,
                withAppleButton: `S'INSCRIRE AVEC APPLE`,
                withGoogleButton: `S'INSCRIRE AVEC GOOGLE`,
                withEmailButton: `S'INSCRIRE AVEC MON EMAIL`,
                socialAndEmailSeparator: `ou`,
            },
            onboarding: {
                firstNamePlaceholder: `Votre prénom`,
                agePlaceholder: `Votre âge`,
                gender: {
                    text: `Comment\nvous identifiez-vous ?`,
                    optional: `Facultatif`,
                },
                referrerCode: {
                    link: `Code de parrainage ?`,
                    placeholder: `Code de parrainage (facultatif)`,
                },
                error: {
                    referrerCodeNotValid: `Le code de parrainage que vous avez saisi n'est pas valide.`,
                    default: `Il y a eu une erreur, réessaiez plus tard`,
                },
                submitButton: `Terminer mon inscription`,
            },
        },
        howItWorks: {
            title: `Comment ça marche ?`,
            signUp: {
                title: `Créez votre compte`,
                subtitle: `Gratuitement et en quelques clics\xa0seulement`,
            },
            useOffer: {
                title: `Faites vos achats`,
                subtitle: `Passez par Joko pour profiter des offres de\xa0cashback`,
            },
            getRewarded: {
                title: `Gagnez de l'argent`,
                subtitle: `Récupérez votre argent par virement\xa0bancaire`,
            },
        },
        signUpButton: `JE M'INSCRIS GRATUITEMENT`,
        testimonials: {
            title: `Plus d'*1 million* de personnes utilisent Joko au quotidien\n et en parlent !`,
        },
        valueProposition: {
            title: `Les avantages *Joko*`,
            shopping: {
                title: `Adapté à vos habitudes d'achat`,
                subtitle: `Un site web, une extension de navigateur et une application mobile pour vous accompagner partout.`,
            },
            customerService: {
                title: `Une équipe à votre écoute`,
                subtitle: `Notre service client, basé en France, est à votre écoute pour vous conseiller et vous accompagner.`,
            },
            rewards: {
                title: `Des récompenses adaptées à vos envies`,
                subtitle: `Votre cagnotte est convertible en argent, en carte cadeau ou en don pour une association.`,
            },
        },
        about: {
            title: `À propos de *Joko*`,
            text:
                `Nous pensons que tout le monde mérite d'être récompensé !\n\n` +
                `C'est avec cette ambition que depuis 2018, les 3 fondateurs de Joko se sont donnés pour objectif d'accompagner tous les Français et toutes les Françaises dans leurs dépenses de tous les jours en les récompensant sur chacune d'elles.\n\n` +
                `Aujourd'hui, Joko c'est un site internet, une application mobile et une extension de navigateur, et déjà plus d'1 million d'adeptes !`,
        },
        footer: {
            contact: `Contact`,
            termsOfService: `Conditions Générales`,
            privacyPolicy: `Politique de Confidentialité`,
        },
    },
    auth: {
        intro: {
            paragraph1: {
                title: 'Toutes vos marques préférées',
                message: "De vos courses quotidiennes à l'organisation de vos vacances, Joko vous récompense !",
            },
            paragraph2: {
                title: `Gagnez de l'argent chez 1200 super-marques`,
                message: 'En ligne ou en magasin, votre carte bleue vous fait gagner à tous les coups !',
            },
            paragraph3: {
                title: 'Des récompenses qui vous ressemblent',
                message:
                    "Convertissez votre cagnotte en cash sur votre compte, en cartes cadeaux ou en dons à des associations, c'est comme vous voulez !",
            },
        },
        signIn: {
            title: `Connectez-vous à votre compte *Joko*`,
            withEmail: {
                subtitle: `Connexion avec votre email :`,
                email: `Adresse email`,
                password: `Mot de passe`,
                resetPassword: `Mot de passe oublié ?`,
                button: `SE CONNECTER`,
                error: {
                    default: `Il y a eu une erreur, réessayez plus tard`,
                    wrongCredentials: `Il y a une erreur dans vos identifiants. Vous pouvez réessayer ou utiliser la fonction "Mot de passe oublié"`,
                    socialAccount: `Pour vous connecter au compte Joko associé à cette adresse email, utilisez la connexion via Facebook ou Apple`,
                    wrongMfaCode: `Le code de confirmation que vous avez entré est incorrect`,
                },
                mfa: {
                    title: `Entrez votre code de confirmation`,
                    subtitle: `Nous vous avons envoyé un code par email. Renseignez-le pour vous connecter.`,
                    codePlaceholder: `Code`,
                    button: `CONTINUER`,
                },
            },
            withFacebook: {
                button: 'CONTINUER AVEC FACEBOOK',
                loading: 'Chargement en cours...',
                error: {
                    default: 'Une erreur est survenue, réessayez de vous connecter',
                },
            },
            withApple: {
                button: 'CONTINUER AVEC APPLE',
                loading: 'Chargement en cours...',
                error: {
                    default: 'Une erreur est survenue, réessayez de vous connecter',
                },
            },
            withGoogle: {
                button: 'CONTINUER AVEC GOOGLE',
                loading: 'Chargement en cours...',
                error: {
                    default: 'Une erreur est survenue, réessayez de vous connecter',
                },
            },
            divider: 'OU',
            signUp: {
                text: `Pas encore de compte ?`,
                button: `M'inscrire gratuitement`,
            },
            signInSucceeded: {
                title: `Connexion réussie !`,
                subtitle: `À partir de maintenant, Joko trouvera les meilleures réductions pendant vos achats sur le navigateur`,
                widget: {
                    title: `Retrouvez l'extension en cliquant sur le puzzle`,
                    subtitle: { part1: `Épinglez`, part2: `l'extension pour la retrouver plus facilement` },
                    button: `J'AI COMPRIS`,
                },
            },
        },
        signUp: {
            title: `Créez votre compte Joko`,
            subtitle: `Rejoignez plus d'1 million de membres et récompensez toutes vos dépenses`,
            withEmailButton: `S'INSCRIRE AVEC MON EMAIL`,
            withFacebookButton: `S'INSCRIRE AVEC FACEBOOK`,
            withAppleButton: `S'INSCRIRE AVEC APPLE`,
            withGoogleButton: `S'INSCRIRE AVEC GOOGLE`,
            divider: 'ou',
            signIn: {
                text: `J'ai déja un compte`,
                button: `Me connecter`,
            },
            withEmail: {
                emailTitle: `Quel est votre email ?`,
                emailPlaceholder: `Votre email`,
                passwordTitle: `Créez votre mot de passe`,
                passwordPlaceholder: `Mot de passe`,
                passwordRequirements: {
                    minCharacters: ({ minCharacters }) =>
                        `Le mot de passe doit au moins avoir *${minCharacters} caractères*. `,
                    minLowerCase: ({ minLowerCase }) => `Avec *${minLowerCase} minuscule${minLowerCase > 1 ? 's' : ''}*, `,
                    minUpperCase: ({ minUpperCase }) => `*${minUpperCase} majuscule${minUpperCase > 1 ? 's' : ''}* et `,
                    minNumbers: ({ minNumbers }) => `*${minNumbers} chiffre${minNumbers > 1 ? 's' : ''}*.`,
                },
                termsOfService: `J'accepte les *conditions générales* et la *politique de confidentialité*.`,
                newsletter: `J'accepte de recevoir les meilleures offres par email. Je peux me désinscrire à tout moment.`,
                nextButton: 'SUIVANT',
                backButton: 'Retour',
                error: {
                    emailNotValid: `Le format de l'email que vous avez saisi n'est pas valide.`,
                    passwordNotValid: `Le format du mot de passe que vous avez saisi n'est pas valide.`,
                    didNotConsentToTermsOfServices: `Vous devez accepter les conditions générales et la politique de confidentialité.`,
                    alreadyExists:
                        `L'email que vous avez saisi est déjà pris. Essayez de vous ` +
                        `connecter ou bien recommencez avec un autre mail.`,
                    default: `Il y a eu une erreur, réessayez plus tard`,
                },
            },
        },
        resetPassword: {
            emailPage: {
                title: `Mot de passe oublié`,
                subtitle: `Saisissez l'adresse électronique associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.`,
                emailPlaceholder: `Adresse email`,
                nextButton: `RÉINITIALISER MON MOT DE PASSE`,
                backButton: `Retourner à la page de connexion`,
                error: {
                    default: `Il y a eu une erreur, réessayez plus tard`,
                    unknownEmail: `Aucun compte n'est associé à cette adresse email`,
                    unverifiedEmail: `Pour que nous puissions vous envoyer un email de réinitialisation nous avons besoin que vous confirmiez d'abord votre email`,
                    generatedPassword: `Il y a bien un compte lié à cet email. Utilisez le bouton *Continuer avec Facebook* ou *Continuer avec Apple* pour vous connecter.`,
                    limitExceeded: `On dirait que vous avez essayé de changer votre mot de passe trop de fois en peu de temps. Réessayez plus tard.`,
                },
            },
            newPasswordPage: {
                title: `Réinitialisez votre mot de passe`,
                subtitle: `Entrez le code reçu par email ou SMS et votre nouveau mot de passe`,
                codePlaceholder: `Code`,
                passwordPlaceholder: `Nouveau mot de passe`,
                nextButton: `SUIVANT`,
                successMessage: {
                    title: `Mot de passe réinitialisé !`,
                    text: `Vous pouvez maintenant vous connecter en utilisant ce nouveau mot de passe`,
                    button: `SE CONNECTER`,
                },
                error: {
                    default: `Il y a eu une erreur, réessayez plus tard`,
                    wrongCode: `Code incorrect`,
                    expiredCode: `Code expiré`,
                    limitExceeded: `On dirait que vous avez essayé de changer votre mot de passe trop de fois en peu de temps. Réessayez plus tard.`,
                },
            },
        },
        browserExtensionAutoLogin: {
            loading: 'Chargement en cours...',
        },
    },
    onboarding: {
        progressIndicator: {
            accountCreationStep: `Compte`,
            userInfoStep: `Informations personnelles`,
            referralStep: `Parrainage`,
        },
        userInfo: {
            firstNameTitle: `Quel est votre prénom ?`,
            firstNamePlaceholder: `Votre prénom`,
            ageTitle: `Quel âge avez-vous ?`,
            agePlaceholder: `Votre âge`,
            genderTitle: `Comment vous identifiez-vous ?`,
            genderSubtitle: `Cette information est *facultative*, elle nous permet de vous proposer des offres plus personnalisées.`,
            genderDescription: {
                female: `Femme`,
                male: `Homme`,
                other: `Non-binaire`,
            },
            nextButton: 'SUIVANT',
        },
        referrerCode: {
            referrerCodeTitle: `Avez-vous un code de parrainage ?`,
            referrerCodePlaceholder: `Optionnel`,
            nextButton: 'TERMINER MON INSCRIPTION',
            backButton: 'Retour',
        },
        loading: 'Connexion en cours',
    },
    home: {
        navigationBar: {
            searchBarPlaceholder: 'Essayez Fnac',
        },
        offers: {
            offerComponent: {
                cashbackDescription: `de cashback`,
                multipleRates: {
                    rateUpTo: "Jusqu'à",
                },
                button: {
                    activate: 'ACTIVER',
                    shop: 'SHOP',
                },
                expirationDate: ({ quantity, unit }: { quantity: number; unit: 'hours' | 'days' }) =>
                    unit === 'hours' ? `${quantity}h` : `${quantity}j`,
            },
            offerDetails: {
                cashback: {
                    cashbackDescription: `de cashback`,
                    valueMinCondition: ({ valueMin }: { valueMin: number }) =>
                        `à partir de ${formatCurrencyAmountWithLocaleFormat(valueMin, { localeFormat: frFormat })}`,
                },
                multipleRates: {
                    title: 'Gains',
                    rateUpTo: "Jusqu'à",
                },
                description: {
                    title: ({ offerTitle }: { offerTitle: string }) => `À propos de ${offerTitle}`,
                    seeMore: 'Voir plus',
                    websiteLink: 'Site internet',
                },
                conditions: {
                    howItWorksTitle: `Comment ça marche`,
                    title: `Conditions`,
                    generic: ({ offerType, active }) =>
                        offerType === OfferType.Online
                            ? `Achetez via le bouton "Shop"`
                            : offerType === OfferType.OneTime && !active
                            ? `Activez l'offre en cliquant sur le bouton ci-dessous et achetez avec la carte bancaire que vous avez connectée à Joko.`
                            : `Achetez avec la carte bancaire que vous avez connectée à Joko`,
                    offerTypeIcon: {
                        cardLinked: `CB`,
                    },
                },
                button: {
                    activate: 'ACTIVER',
                    shop: 'SHOP',
                    unavailable: 'OFFRE TERMINÉE',
                },
            },
            offerSearch: {
                searchResults: ({ searchValue }) => `Résultats pour "${searchValue}"`,
            },
            onlineOfferActivation: {
                offerActivationStatus: {
                    loading: `ACTIVATION EN COURS...`,
                    succeeded: `OFFRE ACTIVÉE`,
                    failed: `ÉCHEC DE L'ACTIVATION`,
                },
                adBlockerMessage: {
                    title: `Un Ad Blocker a été détecté`,
                    subtitle1: `Joko ne fonctionne pas avec votre bloqueur de publicités.`,
                    subtitle2OnDesktop: `Désactivez-le et réessayez pour gagner votre cashback.`,
                    subtitle2OnMobile: `Désactivez-le ou changez de navigateur et réessayez pour gagner votre cashback.`,
                    supportLink: `Obtenir de l'aide`,
                    navigationButtons: {
                        retry: `JE RÉESSAYE`,
                        continue: `JE RENONCE À MON CASHBACK`,
                    },
                },
                cookieWarning: {
                    withoutAcknowledgmentRequired: {
                        title: `N'oubliez pas d'autoriser les cookies`,
                        subtitle: `Autorisez tous les cookies sur le site du marchand et achetez comme d'habitude, vous recevrez votre cashback directement sur votre compte Joko`,
                    },
                    withAcknowledgmentRequired: {
                        title: `N'oubliez pas d'autoriser les cookies`,
                        subtitle: `Autorisez tous les cookies sur le site du marchand pour recevoir votre cashback`,
                        acknowledgeButtonText: `D'ACCORD !`,
                        skipFutureAcknowledgmentText: `Ne plus me le rappeler`,
                    },
                },
            },
            favoriteOffers: {
                pageTitle: 'Offres favorites',
                placeholder: {
                    title: "Pas encore d'offre favorite",
                    subtitle:
                        "Cliquez sur le cœur d'une offre afin de l'ajouter à vos favoris et de la sauvegarder pour plus\xa0tard",
                    exploreButton: 'Explorer les offres',
                },
            },
        },
        history: {
            title: `Cagnotte`,
            tab: {
                all: 'Tous',
                pending: 'En attente',
            },
            sectionTitle: {
                currentWeek: 'Cette semaine',
                lastWeek: 'La semaine dernière',
                earlier: 'Plus tôt',
            },
            information: {
                cashbackDelay: `Vos achats peuvent prendre jusqu'à quelques jours pour s'afficher ici 😉`,
                pendingRecords:
                    `Pourquoi mes gains sont-ils en attente ?\n` +
                    `Nos partenaires mettent souvent quelques semaines et parfois quelques mois ` +
                    `pour valider les transactions.\n` +
                    `Avec un peu de patience, les gains finissent par arriver 😊`,
            },
            item: {
                title: {
                    referral: 'Parrainage',
                    contest: 'Tirage',
                    bankTransfer: 'Retrait cash',
                    giftCard: 'Carte cadeau',
                    donation: 'Don à une association',
                    autoDonation: 'Don automatique',
                },
                date: {
                    today: "aujourd'hui",
                    yesterday: 'hier',
                    numberOfDaysAgo: ({ numberOfDaysAgo }) => `il y a ${numberOfDaysAgo} jours`,
                    date: ({ date }) => `le ${date.format('D MMMM')}`,
                },
                subtitle: {
                    bonus: 'Bonus',
                    contest: 'Participation',
                    bankTransfer: ({ amount }) =>
                        `${formatCurrencyAmountWithLocaleFormat(amount, { localeFormat: frFormat })}`,
                    giftCard: ({ value, name }) =>
                        `${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: frFormat })} chez ${name}`,
                    donation: ({ value, name }) =>
                        `${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: frFormat })} pour ${name}`,
                },
            },
            dialog: {
                retroactiveCashback: {
                    title: "C'est cadeau ✌️",
                    subtitle: ({ numberOngoingOfferRetroactiveDays }) =>
                        `Nous vous avons attribué des gains rétroactivement pour les dépenses ` +
                        `faites chez un de vos abonnements dans les ` +
                        `${numberOngoingOfferRetroactiveDays} jours précédant la connexion de votre banque`,
                    buttonText: 'Trop cool',
                },
            },
        },
        pointCollection: {
            title: 'Collecter mon cashback !',
            button: 'COLLECTER MON CASHBACK',
        },
        profile: {
            menu: {
                settingsSection: {
                    title: `Paramètres`,
                    userInfo: `Mes informations`,
                    termsOfService: `Conditions générales du service`,
                    privacyPolicy: `Politique de confidentialité`,
                    signOut: `Se déconnecter`,
                    deleteUser: `Supprimer mon compte`,
                },
                supportSection: {
                    title: `Support`,
                    faq: `Aide`,
                },
                deleteUserDialog: {
                    title: `Vous confirmez vouloir supprimer votre compte ?`,
                    subtitle: `Votre compte Joko sera supprimé de manière définitive. Il vous sera impossible de revenir en arrière.`,
                    buttons: {
                        yes: `Supprimer mon compte`,
                        no: `Annuler`,
                    },
                    deletionInProgress: `Suppression en cours...`,
                },
            },
            userInfo: {
                title: `Mes informations`,
                personalizeTrackingConsents: `Configurer mes paramètres de suivi`,
                mainSection: {
                    title: `Générales`,
                    email: `Email`,
                    firstName: `Prénom`,
                    gender: `Genre`,
                    unspecifiedGender: `Non spécifié`,
                    age: `Âge`,
                    birthDate: `Date de naissance`,
                    phoneNumber: `Téléphone`,
                    addPhoneNumber: `Ajouter un numéro de téléphone`,
                    verify: {
                        email: `Vérifier l'email`,
                        phoneNumber: `Vérifier le numéro`,
                    },
                },
                emailPage: {
                    title: `Quel est votre email ?`,
                    emailPlaceholder: `Adresse email`,
                    error: {
                        alreadyExists: `L'adresse email que vous avez saisie est déjà prise`,
                        default: `Il y a eu une erreur, réessayez plus tard`,
                    },
                    nextButton: `VALIDER`,
                    backButton: `ANNULER`,
                },
                emailVerificationPage: {
                    title: `On vient de vous envoyer un email`,
                    text: `Vérifiez votre adresse en cliquant sur le lien dans l'email`,
                    button: `OK`,
                },
                phoneNumberPage: {
                    title: `Quel est votre numéro de téléphone ?`,
                    phoneNumberPlaceholder: `Numéro de téléphone`,
                    error: {
                        alreadyExists: `Ce numéro de téléphone est déjà utilisé`,
                        default: `Il y a eu une erreur, réessayez plus tard`,
                    },
                    nextButton: `VALIDER`,
                    backButton: `ANNULER`,
                },
                phoneNumberVerificationPage: {
                    title: `Entrez le code de confirmation`,
                    subtitle: `Nous vous avons envoyé un code de confirmation par SMS. Entrez-le pour confirmer votre numéro.`,
                    codePlaceholder: `Votre code`,
                    success: {
                        text: `Votre numéro a bien été confirmé`,
                        button: `OK`,
                    },
                    error: {
                        default: `Il y a eu une erreur, réessayez plus tard`,
                        wrongCode: `Code de confirmation incorrect`,
                        limitExceeded: `On dirait que vous avez essayé de verifier votre numéro trop de fois en peu de temps. Réessayez plus tard`,
                    },
                    nextButton: `VALIDER`,
                    backButton: `ANNULER`,
                },
            },
        },
        rewards: {
            rewardPage: {
                bankTransfers: {
                    title: `La cash machine`,
                    subtitle: `Transférez vos gains sur votre compte`,
                    amount: ({ minAmount }) =>
                        `${formatCurrencyAmountWithLocaleFormat(minAmount, { localeFormat: frFormat })} minimum`,
                },
                giftCards: {
                    sectionTitle: `Cartes cadeaux`,
                    itemSubtitle: ({ value }) =>
                        `Carte cadeau de ${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: frFormat })}`,
                },
                donations: {
                    sectionTitle: `Associations`,
                    itemSubtitle: ({ value }) =>
                        `Don de ${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: frFormat })}`,
                },
            },
            rewardDetails: {
                button: {
                    redeem: {
                        giftCard: `CONVERTIR MA CAGNOTTE`,
                        donation: `FAIRE UN DON`,
                    },
                    balanceTooLow: `PAS ASSEZ DANS LA CAGNOTTE`,
                },
                confirmationDialog: {
                    title: 'Vous confirmez vouloir convertir votre cagnotte ?',
                    subtitle: ({ rewardType, value, name }) => {
                        const formattedCurrencyValue = formatCurrencyAmountWithLocaleFormat(value, {
                            localeFormat: frFormat,
                        });
                        switch (rewardType) {
                            case RewardType.giftCard:
                                return `Nous allons convertir ${formattedCurrencyValue} de votre cagnotte contre une e-carte cadeau ${name} d'une valeur de ${formattedCurrencyValue}`;
                            case RewardType.donation:
                                return `Nous allons convertir ${formattedCurrencyValue} de votre cagnotte contre un don de ${formattedCurrencyValue} en faveur de ${name}`;
                            default:
                                return '';
                        }
                    },
                    button: {
                        yes: `CONFIRMER`,
                        no: `ANNULER`,
                    },
                },
                successDialog: {
                    title: `Félicitations !`,
                    subtitle: `Vous allez recevoir un email de confirmation`,
                    button: `OK`,
                },
                verificationNeededDialog: {
                    text: ({ phoneNumberVerified, emailVerified }) =>
                        !emailVerified && !phoneNumberVerified
                            ? `Pour convertir votre cagnotte, vous devez d'abord confirmer votre adresse email et votre numéro de téléphone`
                            : !emailVerified
                            ? `Pour convertir votre cagnotte, vous devez d'abord confirmer votre adresse email`
                            : !phoneNumberVerified
                            ? `Pour convertir votre cagnotte, vous devez d'abord confirmer votre numéro de téléphone`
                            : ``,
                    button: {
                        next: `C'est parti`,
                        back: `Plus tard`,
                    },
                },
            },
            bankTransfer: {
                amount: ({ amount }) => `${formatCurrencyAmountWithLocaleFormat(amount, { localeFormat: frFormat })}`,
                redeemButton: {
                    default: `DEMANDER MON VIREMENT`,
                    noAmountSelected: `CHOISISSEZ UN MONTANT`,
                    balanceTooLow: `PAS ASSEZ DANS LA CAGNOTTE`,
                },
                success: {
                    title: 'Félicitations ! Votre virement a bien été pris en compte',
                    subtitle: ({ terms }) => `Il sera validé par nos équipes et exécuté ${terms}`,
                    button: 'Terminer',
                },
            },
            bankDetailsPage: {
                title: `Vos coordonnées bancaires`,
                subtitle: ({ terms }: { terms: string }) =>
                    `Vous recevrez votre virement sur ce compte bancaire${terms ? `, ${terms}` : ''}`,
                bankDetails: {
                    currency: 'EUR',
                    firstName: {
                        label: `Prénom`,
                        placeholder: `Votre prénom`,
                    },
                    lastName: {
                        label: `Nom`,
                        placeholder: `Votre nom`,
                    },
                    iban: {
                        label: `IBAN`,
                        placeholder: `FR76 XXXX XXXX XXXX XXXX XXXX XXX`,
                    },
                    bic: {
                        label: `BIC`,
                        placeholder: `11 caractères`,
                    },
                },
                saveOption: `Enregistrer mes coordonnées pour les prochains virements`,
                button: 'CONTINUER',
                error: {
                    bankDetails: {
                        currency: 'EUR',
                        noFirstName: `Vous devez entrer votre prénom`,
                        noLastName: `Vous devez entrer votre nom de famille`,
                        noIban: `Vous devez entrer votre IBAN`,
                        incorrectBic: `Le BIC que vous avez entré est incorrect`,
                        incorrectIban: `L'IBAN que vous avez entré est incorrect`,
                        unauthorizedCountry: `Virement vers ce pays non autorisé`,
                    },
                    balanceTooLow: `Pas assez dans la cagnotte`,
                    verificationNeeded: `Pour effectuer le virement, vous devez d'abord confirmer votre adresse email et votre numéro de téléphone`,
                    incorrectAmount: `Montant non autorisé`,
                    unknown: `Il y a eu une erreur, réessayez plus tard`,
                },
                backButton: `Retour`,
            },
            iOSDonationRedeem: {
                confirmation: {
                    text1: ({ value, name }) =>
                        `Vous confirmez vouloir utiliser votre cagnotte pour faire un don de ` +
                        `${formatCurrencyAmountWithLocaleFormat(value, {
                            localeFormat: frFormat,
                            shouldNeverUseThreeDecimals: true,
                        })} en faveur de ${name} ?`,
                    text2: `Ce montant sera déduit de votre cagnotte après confirmation.`,
                    confirmButton: `CONFIRMER MON DON`,
                    cancelButton: `ANNULER`,
                },
                success: {
                    title: `Félicitations !`,
                    text1: `Votre don a bien été pris en compte et vous allez bientôt recevoir un email de confirmation.`,
                    text2: `Vous pouvez fermer cette page et revenir sur l'application Joko.`,
                },
                cancellation: {
                    title: `Don annulé`,
                    text1: `Votre don a bien été annulé et n'a pas été retiré de votre cagnotte.`,
                    text2: `Vous pouvez fermer cette page et revenir sur l'application Joko.`,
                },
                error: {
                    title: `Une erreur est survenue`,
                    text1: `Votre demande de don n'a pas pu être traitée.`,
                    text2: `Veuillez fermer cette page et réessayer sur l'application Joko.`,
                },
            },
            iosAutoDonationConfirmation: {
                confirmationForm: {
                    text: (rewardTitle) =>
                        `Voulez-vous activer le don automatique du cashback ? Tout le cashback validé que vous gagnerez sera automatiquement reversé à l'association "${rewardTitle}".`,
                    cancelButton: 'Annuler',
                    submitButton: 'ACTIVER LE DON AUTOMATIQUE',
                },
                cancelledMessage: {
                    title: (rewardTitle) =>
                        `Vous n'avez pas activé l'auto-donation du cashback vers l'association "${rewardTitle}"`,
                    subtitle: "Vous pouvez fermer cette page et revenir sur l'application Joko.",
                },
                confirmedMessage: {
                    title: 'Félicitations !',
                    subtitle: (rewardTitle) =>
                        `Vous avez activé le don automatique du cashback.\n\nTout le cashback validé que vous gagnerez sera automatiquement reversé à l'association ${rewardTitle}.\n\nVous pouvez fermer cette page et revenir sur l'application Joko.`,
                },
            },
        },
        legalUpdates: {
            title: ({ legalUpdateType }: { legalUpdateType: LegalUpdateType }) =>
                `Nous avons mis à jour ` +
                `${legalUpdateType !== LegalUpdateType.privacyPolicy ? `les Conditions Générales` : ``}` +
                `${legalUpdateType === LegalUpdateType.termsOfServiceAndPrivacyPolicy ? ` et ` : ``}` +
                `${legalUpdateType !== LegalUpdateType.termsOfService ? `la Politique de Confidentialité` : ``}`,
            links: {
                termsOfServiceLink: `Lire les Conditions Générales`,
                privacyPolicyLink: `Lire la Politique de Confidentialité`,
            },
            refuseButton: `Refuser les modifications`,
            acceptButton: {
                withoutConsent: `J'ai compris`,
                withConsent: `Accepter les modifications`,
            },
            refusalSection: {
                title: `Votre compte sera supprimé si vous refusez les modifications`,
                text1: ({ legalUpdateType }: { legalUpdateType: LegalUpdateType }) =>
                    `L'utilisation du service Joko nécessite que vous acceptiez les modifications ` +
                    `${legalUpdateType !== LegalUpdateType.privacyPolicy ? `des Conditions Générales` : ``}` +
                    `${legalUpdateType === LegalUpdateType.termsOfServiceAndPrivacyPolicy ? ` et ` : ``}` +
                    `${legalUpdateType !== LegalUpdateType.termsOfService ? `de la Politique de Confidentialité` : ``}` +
                    `. Nous sommes obligés par la loi de supprimer votre compte si vous refusez.`,
                text2: `La suppression de votre compte est irréversible et *tous vos gains seront définitivement perdus*.`,
                confirmButton: `Supprimer mon compte`,
                cancelButton: `Annuler`,
            },
            refusalConfirmationModalText: `Supprimer votre compte est irréversible. Tous les gains seront perdus.`,
        },
        floaPaymentPage: {
            title: 'Renseignez votre carte',
            subtitle: 'Vous ne serez débité que si vous utilisez la carte à usage unique.',
            autoClose: 'Vous allez être redirigé vers le site du marchand. Vous pouvez fermer cette page.',
            buttonName: 'CONFIRMER ET CONTINUER',
        },
        bnplTermsOfService: {
            terms: ({ buttonName }: { buttonName: string }) =>
                `En appuyant sur "${buttonName}", vous reconnaissez avoir lu et accepté`,
            and: 'et',
            floaTermsOfServices: 'les conditions générales du service de FLOA',
            floaConfidentialityPolicy: 'la politique de confidentialité de FLOA',
            jokoTermsOfServices: 'les conditions générales du service paiement en trois fois',
            jokoConfidentialityPolicy: `la politique de confidentialité de Joko`,
        },
    },
    trackingConsents: {
        trackingModal: {
            message: `Nous utilisons des cookies et d'autres technologies similaires afin de personnaliser notre contenu, mesurer l'efficacité de nos publicités et améliorer leur pertinence, ainsi que proposer une meilleure expérience.`,
            privacyPolicyLink: `En savoir plus`,
            acceptButton: `ACCEPTER ET FERMER`,
            personalizeButton: `PERSONNALISER LES CHOIX`,
        },
        trackingPersonalization: {
            title: `Paramètres de suivi`,
            required: `Requis`,
            activateAll: `Tout activer`,
            allActivated: `Tout activé`,
            necessaryTrackers: {
                title: `Les essentiels`,
                subtitle: `Ces outils sont nécessaires au fonctionnement du site web et ne peuvent être désactivés dans nos systèmes.`,
                functionalTrackers: `Éléments nécessaires au fonctionnement de Joko`,
                securityTrackers: `Éléments nécessaires à la sécurité de Joko`,
            },
            performanceTrackers: {
                title: `Performance`,
                subtitle: `Ces outils nous permettent de compter le nombre de visites et faire des analyses statistiques sur l'utilisation, afin de mesurer et d'améliorer les performances.`,
                amplitudeTrackers: `Amplitude`,
            },
            marketingTrackers: {
                title: `Marketing`,
                subtitle: `Ces outils nous permettent de mesurer l'efficacité de nos campagnes marketing.`,
                amplitudeTrackers: `Amplitude`,
                googleTagManagerTrackers: `Suivi des plateformes publicitaires`,
            },
            submitButton: `ENREGISTRER MES CHOIX`,
        },
    },
    mobileSafariExtensionInstallationPage: {
        header: { title: `Activez Joko sur Safari`, left: `1. Activation`, right: `2. Autorisation` },
        backToAppButton: `Revenir à l'application`,
        activateStep: {
            preamble: {
                banner: `Activer l'assistant Joko dans Safari prend moins d’une minute`,
                tosConsent: `J'accepte les *conditions générales* et la *politique de confidentialité*`,
                startButton: `C'est parti !`,
            },
            instructions: {
                title: `Suivez ces étapes pour activer l'extension`,
                firstStep: {
                    title1: `1. Appuyez sur l'icône`,
                    title2: `en bas de votre écran`,
                    subtitle: `L'icône peut aussi se trouver en haut de votre écran dans certains cas`,
                },
                secondStep: `2. Cliquez sur la barre de gestion d'extensions`,
                thirdStep: `3. Activez l'extension Joko`,
            },
        },
        authorizeStep: {
            preamble: {
                startButton: `CONTINUER`,
                title: `Une dernière étape !`,
                subtitle: `Autorisez l'extension Joko à trouver des réductions sur tous les sites`,
            },
            instructions: {
                title: `Suivez ces étapes pour ne rater aucun cashback`,
                firstStep: {
                    title1: `1. Appuyez sur l'icône`,
                    title2: `en bas de votre écran`,
                    subtitle: `L'icône peut aussi se trouver en haut de votre écran dans certains cas`,
                },
                secondStep: `2. Cliquez sur l'extension de Joko`,
                thirdStep: `3. Autorisez tout le temps pour maintenir l'extension active`,
                fourthStep: `4. Autorisez sur chaque site web pour un maximum d'économies`,
            },
            instructionsWithSystemSettings: {
                title: `Suivez ces étapes pour \n autoriser l'extension`,
                firstStep: `1. Cliquez sur "Ouvrir les paramètres"`,
                secondStep: `2. En bas de la page,\n cliquez sur "Autres sites web"`,
                thirdStep: `3. Choisissez "Autoriser"`,
                openSystemSettingsButton: `OUVRIR LES PARAMÈTRES`,
            },
            instructionsWithBanner: {
                firstStep: `1. Cliquez sur "Vérifier" en haut de votre écran`,
                secondStep: `2. Autorisez tout le temps pour ne rien rater`,
                thirdStep: `3. Autorisez sur chaque site web pour un maximum d'économies`,
                missingBannerButton: `Je ne vois pas le bouton "Vérifier"`,
            },
            headerTitle: `Autorisez Joko à fonctionner sur chaque site web`,
            headerSubtitle: `Pour bénéficier de cashback et de codes promo\n*Votre vie privée est protégée*`,
        },
        conclusion: {
            title: `Félicitations !`,
            subtitle: `Joko trouvera maintenant les meilleures réductions pendant vos achats sur Safari`,
            connectInAppSubtitle: `Finissez votre inscription sur l'application Joko`,
            connectInAppButton: `FINIR MON INSCRIPTION`,
            returnToJokoButton: `Retourner sur Joko`,
            headerTitle: `Et voilà !`,
            headerSubtitle: `À vous les économies automatiques sur Safari`,
        },
        missingAuthorization: {
            allWebsitesBeforeRetry: {
                title: `Autorisations manquantes`,
                subtitle: `Vous devez autoriser Joko à fonctionner "toujours" et sur "tous les sites" pour activer le cashback`,
                button: `CORRIGER`,
            },
            allWebsitesAfterRetry: {
                title: `Autorisation échouée`,
                subtitle: `Il semble y avoir un problème avec les autorisations de Joko. Vous pouvez les corriger depuis les paramètres de Safari de votre téléphone.`,
            },
            default: {
                title: `Joko n'est pas actif !`,
                subtitle: `Autorisez l'extension Joko à trouver des réductions sur tous les sites`,
                button: `FINIR L'ACTIVATION`,
                finishLaterButton: `Plus tard`,
            },
            headerTitle: `Oups, vous n'avez pas donné toutes les autorisations`,
            headerSubtitle: `C'est indispensable pour permettre à Joko de fonctionner sur tous les sites partenaires\n*Votre vie privée est protégée*`,
            button: `Accéder aux paramètres`,
        },
        wrongBrowser: {
            title: `L'extension Joko n'est pas disponible sur ce navigateur`,
            subtitle: `L'extension Joko pour mobile n'est disponible pour l'instant que sur Safari`,
        },
    },
    mobileSafariExtensionInstallationPagesRevamped: {
        instructionsClickSafariSettings: {
            part1: `Cliquez sur`,
            part2: `dans la barre d'adresse`,
        },
        activateStep: {
            preamble: {
                tosConsent: `J'accepte les *conditions générales* et la *politique de confidentialité*`,
                startButton: `C'est parti !`,
            },
            instructionsManageExtensions: {
                part1: `Selectionnez`,
                part2: `Gérer les extensions`,
            },
            instructionsActivateJokoExtension: `Cliquez sur l'extension Joko`,
            headerTitle: `Activez Joko sur Safari`,
        },
        authorizeStep: {
            instructionsClickBanner: {
                part1: `Cliquez sur `,
                part2: `“Vérifier”`,
                part3: `en haut à droite ${FINGER_POINTING_UP_EMOJI}`,
            },
            instructionsClickExtensionWarningIcon: {
                part1: 'Cliquez sur le',
                part2: 'à côté de Joko',
            },
            instructionsAlwaysAllow: {
                part1: 'Cliquez sur',
                part2: 'Toujours autoriser',
            },
            instructionsAllowOnAllWebsites: {
                part1: 'Cliquez sur',
                part2: 'Autoriser sur chaque site web',
            },
            missingBannerButton: `Je ne vois pas le bouton "Vérifier"`,
            headerTitle: `Autorisez Joko à fonctionner sur chaque site web`,
            headerSubtitle: `Pour bénéficier de cashback et de codes promo\n*Votre vie privée est protégée*`,
        },
        missingAuthorization: {
            instructionsSystemSettingsClickButton: `Accédez aux paramètres`,
            instructionsSystemSettingsOtherWebsites: {
                part1: `Cliquez sur`,
                part2: `Autres sites web`,
            },
            instructionsSystemSettingsAllow: {
                part1: `Cliquez sur`,
                part2: `Autoriser`,
            },
            systemSettingsButton: `Accédez aux paramètres`,
            headerTitle: `Oups, vous n'avez pas donné toutes les autorisations`,
            headerSubtitle: `Joko ne peut pas fonctionner sans toutes les autorisations. *Votre vie privée est protégée*`,
        },
        conclusion: {
            returnToJokoButton: `Retourner sur Joko`,
            headerTitle: `Et voilà !`,
            headerSubtitle: `À vous les économies automatiques sur Safari`,
        },
    },
};
