import { OfferType } from '../api/graphql/fragments/offers';
import { RewardType } from '../api/graphql/fragments/rewards';
import { LegalUpdateType } from '../api/graphql/fragments/legalUpdates';
import { LocalizedTexts, LocalizedFormat, formatCurrencyAmountWithLocaleFormat } from './utils';
import { FINGER_POINTING_UP_EMOJI } from '../components/onboarding/mobileSafariExtension/Styles';

export const enGBFormat: LocalizedFormat = {
    number: {
        format: {
            delimiter: ',',
            separator: '.',
        },
    },
    currency: {
        symbol: '£',
        shouldSymbolComeInFront: true,
        separator: '',
    },
};

export const enGBTexts: LocalizedTexts = {
    landing: {
        title: 'Joko: cashback and good deals - Free to use',
        header: {
            alreadySignedUp: `Do you already have an account?`,
            signIn: `Log in`,
            presentation: {
                title: `Earn money on your\xa0purchases`,
                subtitle: `Sign up for Joko for free to earn cashback on over 1000 brands`,
                moreMerchants: `and many others...`,
            },
            signUp: {
                title: `Create your Joko account\nfor free`,
                withFacebookButton: `SIGN UP WITH FACEBOOK`,
                withAppleButton: `SIGN UP WITH APPLE`,
                withGoogleButton: `SIGN UP WITH GOOGLE`,
                withEmailButton: `SIGN UP WITH EMAIL`,
                socialAndEmailSeparator: `or`,
            },
            onboarding: {
                firstNamePlaceholder: `Your first name`,
                agePlaceholder: `Your age`,
                gender: {
                    text: `How do\nyou identify?`,
                    optional: `Optional`,
                },
                referrerCode: {
                    link: `Referral code?`,
                    placeholder: `Referral code (optional)`,
                },
                error: {
                    referrerCodeNotValid: `The referral code you typed in is not valid.`,
                    default: `There was an error, try again later`,
                },
                submitButton: `Finish signing up`,
            },
        },
        howItWorks: {
            title: `How does it work?`,
            signUp: {
                title: `Create your account`,
                subtitle: `For free in just a few\xa0clicks`,
            },
            useOffer: {
                title: `Make your purchases`,
                subtitle: `Pass by Joko to benefit from cashback\xa0offers`,
            },
            getRewarded: {
                title: `Earn money`,
                subtitle: `Cash out your money to a bank\xa0account`,
            },
        },
        signUpButton: `SIGN UP FOR FREE`,
        testimonials: {
            title: `More than *1 million* people use Joko daily\n and they speak about us!`,
        },
        valueProposition: {
            title: `The *Joko* benefits`,
            shopping: {
                title: `Adapted to your purchase habits`,
                subtitle: `A website, a browser extension and a mobile app to be by your side everywhere.`,
            },
            customerService: {
                title: `A team to help anytime`,
                subtitle: `Our customer service team is available to help with any questions you may have.`,
            },
            rewards: {
                title: `Rewards you actually want`,
                subtitle: `Your balance can be converted to bank transfers, gift cards or donations to charities.`,
            },
        },
        about: {
            title: `About *Joko*`,
            text:
                `We think everyone deserves to be rewarded!\n\n` +
                `It's with this ambition that since 2018, the three founders of Joko decided to focus on accompanying Joko's users on all their purchases by rewarding each of them.\n\n` +
                `Today, Joko is a website, a mobile app and a browser extension, with over 1 million users!`,
        },
        footer: {
            contact: `Contact`,
            termsOfService: `Terms of Service`,
            privacyPolicy: `Privacy Policy`,
        },
    },
    auth: {
        intro: {
            paragraph1: {
                title: 'All your favourite brands',
                message: 'From your daily grocery purchases to planning your vacations, Joko is by your side!',
            },
            paragraph2: {
                title: `Earn cashback on more than 1000 super brands`,
                message: 'Online or in-store, your card will earn you money with every purchase!',
            },
            paragraph3: {
                title: 'Rewards adapted for you',
                message:
                    "Convert your balance into cash on your account, gift cards or donations to charities – it's as you want!",
            },
        },
        signIn: {
            title: `Connect to your *Joko* account`,
            withEmail: {
                subtitle: `Connect with your email:`,
                email: `Email address`,
                password: `Password`,
                resetPassword: `Forgot password?`,
                button: `LOGIN`,
                error: {
                    default: `There has been an error, please try again later`,
                    wrongCredentials: `There is an error in your credentials. You can try again or use the "Forgot password" function`,
                    socialAccount: `To connect to the Joko account associated with this email address, use the connection via Facebook or Apple`,
                    wrongMfaCode: `The confirmation code you have entered is incorrect`,
                },
                mfa: {
                    title: `Enter your confirmation code`,
                    subtitle: `We have sent you a code by email. Enter the code to log in.`,
                    codePlaceholder: `Code`,
                    button: `Continue`,
                },
            },
            withFacebook: {
                button: 'CONNECTION WITH FACEBOOK',
                loading: 'Loading...',
                error: {
                    default: 'An error has occurred, please try again later',
                },
            },
            withApple: {
                button: 'CONNECTION WITH APPLE',
                loading: 'Loading...',
                error: {
                    default: 'An error has occurred, please try again later',
                },
            },
            withGoogle: {
                button: 'CONNECTION WITH GOOGLE',
                loading: 'Loading...',
                error: {
                    default: 'An error has occurred, please try again later',
                },
            },
            divider: 'OR',
            signUp: {
                text: `No account yet`,
                button: `Register`,
            },
            signInSucceeded: {
                title: `Congratulations!`,
                subtitle: `From now on, Joko will find the best deals while you are shopping on Safari`,
                widget: {
                    title: `Find the extension by clicking on the puzzle`,
                    subtitle: { part1: `Pin`, part2: `the extension to find it easier` },
                    button: `GOT IT`,
                },
            },
        },
        signUp: {
            title: `Create your Joko account`,
            subtitle: `Join over 1 million members and start gaining on your spending`,
            withEmailButton: `REGISTER WITH MY EMAIL`,
            withFacebookButton: `REGISTER WITH FACEBOOK`,
            withAppleButton: `REGISTER WITH APPLE`,
            withGoogleButton: `REGISTER WITH GOOGLE`,
            divider: 'or',
            signIn: {
                text: `I already have an account`,
                button: `Log in`,
            },
            withEmail: {
                emailTitle: `What is your email?`,
                emailPlaceholder: `Your email`,
                passwordTitle: `Set your password`,
                passwordPlaceholder: `Password`,
                passwordRequirements: {
                    minCharacters: ({ minCharacters }) => `Your password must have at least *${minCharacters} characters*. `,
                    minLowerCase: ({ minLowerCase }) => `With *${minLowerCase} lowercase${minLowerCase > 1 ? 's' : ''}*, `,
                    minUpperCase: ({ minUpperCase }) => `*${minUpperCase} uppercase${minUpperCase > 1 ? 's' : ''}* and `,
                    minNumbers: ({ minNumbers }) => `*${minNumbers} number${minNumbers > 1 ? 's' : ''}*.`,
                },
                termsOfService: `I accept the *terms and conditions* and the *privacy policy*.`,
                newsletter: `I agree to receive the best offers by email. I can unsubscribe at any time.`,
                nextButton: 'NEXT',
                backButton: 'Return',
                error: {
                    emailNotValid: `The format of the email you have provided is not valid.`,
                    passwordNotValid: `The format of the password you have provided is not valid.`,
                    didNotConsentToTermsOfServices: `You must accept the terms and conditions and the privacy policy.`,
                    alreadyExists: `The email you have provided is already taken. Try to connect or start again with a new email.`,
                    default: `There has been an error, please try again later`,
                },
            },
        },
        resetPassword: {
            emailPage: {
                title: `Forgot your password`,
                subtitle: `Type your email associated with your account and we will send you a link to reset your password.`,
                emailPlaceholder: `Email address`,
                nextButton: `RESET MY PASSWORD`,
                backButton: `Return to the connection page`,
                error: {
                    default: `An error has occurred, please try again later`,
                    unknownEmail: `No account is associated with this email address`,
                    unverifiedEmail: `In order for us to send you a reset email we need you to confirm your email first`,
                    generatedPassword: `There is an account linked to this email. Use the *Continue with Facebook* button or *Continue with Apple* to connect`,
                    limitExceeded: `Looks like you tried to change your password too many times. Try again later.`,
                },
            },
            newPasswordPage: {
                title: `Reset your password`,
                subtitle: `Enter the code received by email or SMS and your new password`,
                codePlaceholder: `Code`,
                passwordPlaceholder: `New password`,
                nextButton: `NEXT`,
                successMessage: {
                    title: `Password reset!`,
                    text: `You can now log in using this new password`,
                    button: `LOG IN`,
                },
                error: {
                    default: `There has been an error, please try again later.`,
                    wrongCode: `Incorrect code`,
                    expiredCode: `Code is expired`,
                    limitExceeded: `Looks like you tried to change your password too many times. Try again later.`,
                },
            },
        },
        browserExtensionAutoLogin: {
            loading: 'Loading...',
        },
    },
    onboarding: {
        progressIndicator: {
            accountCreationStep: `Account`,
            userInfoStep: `Personal information`,
            referralStep: `Referral`,
        },
        userInfo: {
            firstNameTitle: `What is your first name?`,
            firstNamePlaceholder: `First name`,
            ageTitle: `How old are you?`,
            agePlaceholder: `Your age`,
            genderTitle: `How do you identify yourself?`,
            genderSubtitle: `This information is *optional*, it allows us to offer you more tailored offers.`,
            genderDescription: {
                female: `Woman`,
                male: `Man`,
                other: `Non-binary`,
            },
            nextButton: 'NEXT',
        },
        referrerCode: {
            referrerCodeTitle: `Do you have a referral code?`,
            referrerCodePlaceholder: `Optional`,
            nextButton: 'FINISH MY REGISTRATION',
            backButton: 'Return',
        },
        loading: 'Loading',
    },
    home: {
        navigationBar: {
            searchBarPlaceholder: 'Try AliExpress',
        },
        offers: {
            offerComponent: {
                cashbackDescription: `of cashback`,
                multipleRates: {
                    rateUpTo: 'Up to',
                },
                button: {
                    activate: 'ACTIVATE',
                    shop: 'SHOP',
                },
                expirationDate: ({ quantity, unit }: { quantity: number; unit: 'hours' | 'days' }) =>
                    unit === 'hours' ? `${quantity}h` : `${quantity}j`,
            },
            offerDetails: {
                cashback: {
                    cashbackDescription: `of cashback`,
                    valueMinCondition: ({ valueMin }: { valueMin: number }) =>
                        `Minimum ${formatCurrencyAmountWithLocaleFormat(valueMin, { localeFormat: enGBFormat })}`,
                },
                multipleRates: {
                    title: 'Rates',
                    rateUpTo: 'Up to',
                },
                description: {
                    title: ({ offerTitle }: { offerTitle: string }) => `About ${offerTitle}`,
                    seeMore: 'See more',
                    websiteLink: 'Website',
                },
                conditions: {
                    howItWorksTitle: `How it works`,
                    title: `Conditions`,
                    generic: ({ offerType, active }) =>
                        offerType === OfferType.Online
                            ? `Buy with the button "Shop"`
                            : offerType === OfferType.OneTime && !active
                            ? `Activate the offer by clicking on the button below and buy with the bank card you have connected to Joko.`
                            : `Buy with the bank card you have connected to Joko`,
                    offerTypeIcon: {
                        cardLinked: `CC`,
                    },
                },
                button: {
                    activate: 'ACTIVATE',
                    shop: 'SHOP',
                    unavailable: 'OFFER ENDED',
                },
            },
            offerSearch: {
                searchResults: ({ searchValue }) => `Results for "${searchValue}"`,
            },
            onlineOfferActivation: {
                offerActivationStatus: {
                    loading: `ACTIVATION IN PROGRESS...`,
                    succeeded: `ACTIVATED OFFER`,
                    failed: `ACTIVATION FAILED`,
                },
                adBlockerMessage: {
                    title: `An Ad blocker has been detected`,
                    subtitle1: `Joko does not work with Ad blockers.`,
                    subtitle2OnDesktop: `Disable the Ad blocker and try again to earn your cashback.`,
                    subtitle2OnMobile: `Deactivate the Ad blocker or change browser and try again to gain your cashback.`,
                    supportLink: `Get support`,
                    navigationButtons: {
                        retry: `TRY AGAIN`,
                        continue: `WAIVE MY CASHBACK`,
                    },
                },
                cookieWarning: {
                    withoutAcknowledgmentRequired: {
                        title: `Remember to allow cookies when prompted`,
                        subtitle: `Allow all cookies on the merchant's site and shop as usual, you will receive your cashback directly to your Joko account.`,
                    },
                    withAcknowledgmentRequired: {
                        title: `Remember to allow cookies when prompted`,
                        subtitle: `Allow all cookies on the merchant's site to receive your cashback.`,
                        acknowledgeButtonText: `OK`,
                        skipFutureAcknowledgmentText: `Do not remind me anymore`,
                    },
                },
            },
            favoriteOffers: {
                pageTitle: 'Favourite offers',
                placeholder: {
                    title: 'No favourite offers yet',
                    subtitle: 'Click on the heart button of an offer to add it to your favourites and save it for later',
                    exploreButton: 'Explore the offers',
                },
            },
        },
        history: {
            title: `My Balance`,
            tab: {
                all: 'All',
                pending: 'Pending',
            },
            sectionTitle: {
                currentWeek: 'This week',
                lastWeek: 'Last week',
                earlier: 'Earlier',
            },
            information: {
                cashbackDelay: `Your purchases may take up to a few days to show up here.`,
                pendingRecords:
                    `Why are my earnings pending?\n` +
                    `Our partners often take a few weeks and sometimes a few months ` +
                    `to validate transactions.\n` +
                    `With a little patience, your cashback will arrive.`,
            },
            item: {
                title: {
                    referral: 'Referral',
                    contest: 'Contest',
                    bankTransfer: 'Bank transfer',
                    giftCard: 'Gift card',
                    donation: 'Donation',
                    autoDonation: 'Auto-donations',
                },
                date: {
                    today: 'today',
                    yesterday: 'yesterday',
                    numberOfDaysAgo: ({ numberOfDaysAgo }) => `${numberOfDaysAgo} days ago`,
                    date: ({ date }) => `the ${date.format('D MMMM')}`,
                },
                subtitle: {
                    bonus: 'Bonus',
                    contest: 'Participation',
                    bankTransfer: ({ amount }) => formatCurrencyAmountWithLocaleFormat(amount, { localeFormat: enGBFormat }),
                    giftCard: ({ value, name }) =>
                        `${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: enGBFormat })} chez ${name}`,
                    donation: ({ value, name }) =>
                        `${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: enGBFormat })} pour ${name}`,
                },
            },
            dialog: {
                retroactiveCashback: {
                    title: "It's on us",
                    subtitle: ({ numberOngoingOfferRetroactiveDays }) =>
                        `We awarded you cashback as you spent ` +
                        `with one of your subscriptions in the last` +
                        `${numberOngoingOfferRetroactiveDays} days prior to your bank connection`,
                    buttonText: 'Cool',
                },
            },
        },
        pointCollection: {
            title: 'Collect my cashback!',
            button: 'COLLECT MY CASHBACK',
        },
        profile: {
            menu: {
                settingsSection: {
                    title: `Settings`,
                    userInfo: `My information`,
                    termsOfService: `Terms of service`,
                    privacyPolicy: `Privacy policy`,
                    signOut: `Sign out`,
                    deleteUser: `Delete my account`,
                },
                supportSection: {
                    title: `Support`,
                    faq: `Help`,
                },
                deleteUserDialog: {
                    title: `Are you sure you want to delete your account?`,
                    subtitle: `Your Joko account will be permanently deleted. You will not be able to recover your account.`,
                    buttons: {
                        yes: `Delete my account`,
                        no: `Cancel`,
                    },
                    deletionInProgress: `Account deletion in progress...`,
                },
            },
            userInfo: {
                title: `My information`,
                personalizeTrackingConsents: `Change my tracking consents`,
                mainSection: {
                    title: `General`,
                    email: `Email`,
                    firstName: `First Name`,
                    gender: `Gender`,
                    unspecifiedGender: `Not specified`,
                    age: `Age`,
                    birthDate: `Date of Birth`,
                    phoneNumber: `Phone number`,
                    addPhoneNumber: `Add a phone number`,
                    verify: {
                        email: `Verify email`,
                        phoneNumber: `Verify phone number`,
                    },
                },
                emailPage: {
                    title: `What is your email?`,
                    emailPlaceholder: `Email address`,
                    error: {
                        alreadyExists: `The email address you have entered is already taken`,
                        default: `There is an error, please try again later`,
                    },
                    nextButton: `NEXT`,
                    backButton: `CANCEL`,
                },
                emailVerificationPage: {
                    title: `We just sent you an email`,
                    text: `Verify your email address by clicking on the link in the email`,
                    button: `OK`,
                },
                phoneNumberPage: {
                    title: `What is your phone number?`,
                    phoneNumberPlaceholder: `Phone number`,
                    error: {
                        alreadyExists: `This phone number is already taken`,
                        default: `There is an error, please try again later`,
                    },
                    nextButton: `NEXT`,
                    backButton: `CANCEL`,
                },
                phoneNumberVerificationPage: {
                    title: `Enter the confirmation code`,
                    subtitle: `We have sent you a confirmation code by SMS. Enter the code to confirm your number.`,
                    codePlaceholder: `Your code`,
                    success: {
                        text: `Your number has been confirmed`,
                        button: `OK`,
                    },
                    error: {
                        default: `There has been an error, please try again later`,
                        wrongCode: `Confirmation code is incorrect`,
                        limitExceeded: `Looks like you tried to verify your number too many times. Try again later.`,
                    },
                    nextButton: `NEXT`,
                    backButton: `BACK`,
                },
            },
        },
        rewards: {
            rewardPage: {
                bankTransfers: {
                    title: `The cash machine`,
                    subtitle: `Withdraw your balance to your account`,
                    amount: ({ minAmount }) =>
                        `${formatCurrencyAmountWithLocaleFormat(minAmount, { localeFormat: enGBFormat })} minimum`,
                },
                giftCards: {
                    sectionTitle: `Gift cards`,
                    itemSubtitle: ({ value }) =>
                        `Gift card of ${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: enGBFormat })}`,
                },
                donations: {
                    sectionTitle: `Charities`,
                    itemSubtitle: ({ value }) =>
                        `Donation of ${formatCurrencyAmountWithLocaleFormat(value, { localeFormat: enGBFormat })}`,
                },
            },
            rewardDetails: {
                button: {
                    redeem: {
                        giftCard: `PURCHASE WITH MY BALANCE`,
                        donation: `DONATE`,
                    },
                    balanceTooLow: `NOT ENOUGH IN YOUR BALANCE`,
                },
                confirmationDialog: {
                    title: 'Do you confirm you want to convert your balance?',
                    subtitle: ({ rewardType, value, name }) => {
                        const formattedCurrencyValue = formatCurrencyAmountWithLocaleFormat(value, {
                            localeFormat: enGBFormat,
                        });
                        switch (rewardType) {
                            case RewardType.giftCard:
                                return `We will convert ${formattedCurrencyValue} from your balance to a gift card ${name} of ${formattedCurrencyValue}`;
                            case RewardType.donation:
                                return `We will convert ${formattedCurrencyValue} from your balance to a ${formattedCurrencyValue} donation to ${name}`;
                            default:
                                return '';
                        }
                    },
                    button: {
                        yes: `CONFIRM`,
                        no: `CANCEL`,
                    },
                },
                successDialog: {
                    title: `Congratulations!`,
                    subtitle: `You will receive an email of confirmation`,
                    button: `OK`,
                },
                verificationNeededDialog: {
                    text: ({ phoneNumberVerified, emailVerified }) =>
                        !emailVerified && !phoneNumberVerified
                            ? `To convert your balance, you will need to confirm your email address and phone number`
                            : !emailVerified
                            ? `To convert your balance, you will need to confirm your email address`
                            : !phoneNumberVerified
                            ? `To convert your balance, you will need to confirm your phone number`
                            : ``,
                    button: {
                        next: `Let's do it`,
                        back: `Later`,
                    },
                },
            },
            bankTransfer: {
                amount: ({ amount }) => `${formatCurrencyAmountWithLocaleFormat(amount, { localeFormat: enGBFormat })}`,
                redeemButton: {
                    default: `WITHDRAW MY BALANCE`,
                    noAmountSelected: `CHOOSE AN AMOUNT`,
                    balanceTooLow: `NOT ENOUGH IN YOUR BALANCE`,
                },
                success: {
                    title: 'Congratulations! Your transfer has been sent through.',
                    subtitle: ({ terms }) => `It will be validated by our teams and executed ${terms}`,
                    button: 'Complete',
                },
            },
            bankDetailsPage: {
                title: `Your bank account details`,
                subtitle: ({ terms }: { terms: string }) =>
                    `You will receive your withdrawal on this account${terms ? `, ${terms}` : ''}`,
                bankDetails: {
                    currency: 'GBP',
                    fullName: {
                        label: `Full name`,
                        placeholder: `Your name`,
                    },
                    accountNumber: {
                        label: `Account number`,
                        placeholder: `Your account number`,
                    },
                    sortCode: {
                        label: `Sort code`,
                        placeholder: `6 digits`,
                    },
                },
                saveOption: `Save my details for future transfers`,
                button: 'NEXT',
                error: {
                    bankDetails: {
                        currency: 'GBP',
                        noFullName: `You must provide your full name`,
                        noAccountNumber: `You must provide an account number`,
                        noSortCode: `You must provide a sort code`,
                    },
                    balanceTooLow: `Not enough in your balance`,
                    verificationNeeded: `To perform the withdrawal, you must first confirm your email address and phone number`,
                    incorrectAmount: `Incorrect amount`,
                    unknown: `An error occurred, please try again later`,
                },
                backButton: `Back`,
            },
            iOSDonationRedeem: {
                confirmation: {
                    text1: ({ value, name }) =>
                        `Do you confirm that you want to use your balance to make a donation of ` +
                        `${formatCurrencyAmountWithLocaleFormat(value, {
                            localeFormat: enGBFormat,
                            shouldNeverUseThreeDecimals: true,
                        })} to ${name}?`,
                    text2: `This amount will be deducted from your balance after confirmation.`,
                    confirmButton: `CONFIRM MY DONATION`,
                    cancelButton: `CANCEL`,
                },
                success: {
                    title: `Congratulations!`,
                    text1: `Your donation has been sent through you will receive a confirmation email.`,
                    text2: `You can now close this page and come back to the Joko app.`,
                },
                cancellation: {
                    title: `Cancelled donation`,
                    text1: `Your donation has been cancelled and has not been deducted from your balance.`,
                    text2: `You can now close this page and come back to the Joko app.`,
                },
                error: {
                    title: `An error has occurred`,
                    text1: `Your donation request has not been submitted.`,
                    text2: `Veuillez fermer cette page et réessayer sur l'application Joko.`,
                },
            },
            iosAutoDonationConfirmation: {
                confirmationForm: {
                    text: (rewardTitle) =>
                        `Do you want to activate auto-donations? All cashback earned will be automatically donated to the charity "${rewardTitle}"`,
                    cancelButton: 'Cancel',
                    submitButton: 'ACTIVATE AUTO-DONATIONS',
                },
                cancelledMessage: {
                    title: (rewardTitle) =>
                        `Vous n'avez pas activé l'auto-donation du cashback vers l'association "${rewardTitle}"`,
                    subtitle: "Vous pouvez fermer cette page et revenir sur l'application Joko.",
                },
                confirmedMessage: {
                    title: 'Congratulations!',
                    subtitle: (rewardTitle) =>
                        `You have activated auto-donations.\n\nAll verified cashback you earn will be automatically donated to ${rewardTitle}.\n\nYou can close this page and come back on the Joko app.`,
                },
            },
        },
        legalUpdates: {
            title: ({ legalUpdateType }: { legalUpdateType: LegalUpdateType }) =>
                `We have updated the Terms of Service` +
                `${legalUpdateType !== LegalUpdateType.privacyPolicy ? `l` : ``}` +
                `${legalUpdateType === LegalUpdateType.termsOfServiceAndPrivacyPolicy ? ` and ` : ``}` +
                `${legalUpdateType !== LegalUpdateType.termsOfService ? `the Privacy Policy` : ``}`,
            links: {
                termsOfServiceLink: `Read the Terms of Service`,
                privacyPolicyLink: `Read the Privacy Policy`,
            },
            refuseButton: `Refuse the updates`,
            acceptButton: {
                withoutConsent: `I have understood`,
                withConsent: `I accept the new updates`,
            },
            refusalSection: {
                title: `Your account will be deleted if you refuse the new updates`,
                text1: ({ legalUpdateType }: { legalUpdateType: LegalUpdateType }) =>
                    `In order to benefit from the Joko services, you must accept the new updates ` +
                    `${legalUpdateType !== LegalUpdateType.privacyPolicy ? `of the Terms of Services` : ``}` +
                    `${legalUpdateType === LegalUpdateType.termsOfServiceAndPrivacyPolicy ? ` and ` : ``}` +
                    `${legalUpdateType !== LegalUpdateType.termsOfService ? `of the Privacy Policy` : ``}` +
                    `. We are obliged by law to delete your account if you refuse.`,
                text2: `Deleting your account is irreversible and *your balance will be lost*.`,
                confirmButton: `Delete my account`,
                cancelButton: `Cancel`,
            },
            refusalConfirmationModalText: `Deleting your account is irreversible. Your balance will be lost.`,
        },
        floaPaymentPage: {
            title: 'Enter your card details',
            subtitle: 'Your personal card will only be charged if you use the single use card.',
            autoClose: 'You will be automatically redirected to the merchant website. You can close this page.',
            buttonName: 'CONFIRM AND CONTINUE',
        },
        bnplTermsOfService: {
            terms: ({ buttonName }: { buttonName: string }) =>
                `By clicking on "${buttonName}", you confirm having read and accepted`,
            and: 'and',
            floaTermsOfServices: `FLOA's terms of services`,
            floaConfidentialityPolicy: `FLOA's confidentiality policy`,
            jokoTermsOfServices: `Joko's terms of services`,
            jokoConfidentialityPolicy: `Joko's confidentiality policy`,
        },
    },
    trackingConsents: {
        trackingModal: {
            message: `We use cookies and other similar technologies in order to tailor our content, so we can bring you the best possible experience.`,
            privacyPolicyLink: `Read more`,
            acceptButton: `ACCEPT AND CLOSE`,
            personalizeButton: `PERSONALIZE MY CHOICES`,
        },
        trackingPersonalization: {
            title: `Tracking settings`,
            required: `Required`,
            activateAll: `Activate all`,
            allActivated: `Activated`,
            necessaryTrackers: {
                title: `The essentials`,
                subtitle: `These tools are necessary for the operation of the website and cannot be deactivated in our systems.`,
                functionalTrackers: `Required elements for Joko's operations`,
                securityTrackers: `Required elements for Joko's security`,
            },
            performanceTrackers: {
                title: `Performance`,
                subtitle: `These tools allow us to count the number of visits and run statistical analysis on usage, in order to measure and improve performance.`,
                amplitudeTrackers: `Amplitude`,
            },
            marketingTrackers: {
                title: `Marketing`,
                subtitle: `These tools allow us to measure the efficiency of our marketing campaigns.`,
                amplitudeTrackers: `Amplitude`,
                googleTagManagerTrackers: `Monitoring of advertising platforms`,
            },
            submitButton: `SAVE MY CHOICES`,
        },
    },
    mobileSafariExtensionInstallationPage: {
        header: { title: `Activate Joko on Safari`, left: `1. Activation`, right: `2. Authorization` },
        backToAppButton: `Return to app`,
        activateStep: {
            preamble: {
                banner: `It takes less than a minute to activate Joko on Safari`,
                tosConsent: `I accept the *terms of service* and *privacy policy*`,
                startButton: `Let's go!`,
            },
            instructions: {
                title: `Follow these steps to activate the extension`,
                firstStep: {
                    title1: `1. Touch the`,
                    title2: `icon at the bottom of the screen`,
                    subtitle: `The icon can also be at the top of the screen in some cases`,
                },
                secondStep: `2. Select the extension menu`,
                thirdStep: `3. Activate the Joko extension`,
            },
        },
        authorizeStep: {
            preamble: {
                startButton: `CONTINUE`,
                title: `One last step!`,
                subtitle: `You need to authorize the Joko extension in order to activate cashback on all websites`,
            },
            instructions: {
                title: `Follow these steps to avoid missing out on any cashback`,
                firstStep: {
                    title1: `1. Touch the`,
                    title2: `icon at the bottom of the screen`,
                    subtitle: `The icon can also be at the top of the screen in some cases`,
                },
                secondStep: `2. Select the Joko extension`,
                thirdStep: `3. Select 'always allow'`,
                fourthStep: `4. Authorize for all websites to enable the extension for any merchant`,
            },
            instructionsWithSystemSettings: {
                title: `In order to authorize Joko, you need to update your Safari settings`,
                firstStep: `1. Tap the "Open settings" button`,
                secondStep: `2. At the bottom of the page, select "Other Websites"`,
                thirdStep: `3. Select "Allow"`,
                openSystemSettingsButton: `OPEN SETTINGS`,
            },
            instructionsWithBanner: {
                firstStep: `1. Touch the "Review" button at the of the screen`,
                secondStep: `2. Authorize for all time in order to always be informed`,
                thirdStep: `3. Authorize for all websites to enable the extension for any merchant`,
                missingBannerButton: `I cannot find the "Review" button`,
            },
            headerTitle: `Authorize Joko to work on all websites`,
            headerSubtitle: `To benefit from cashback and promo codes\n*Your privacy is protected*`,
        },
        conclusion: {
            title: `Congratulations!`,
            subtitle: `From now on, Joko will find the best deals while you are shopping on Safari`,
            connectInAppSubtitle: `Finish signing up in the Joko app`,
            connectInAppButton: `FINISH SIGNING UP`,
            returnToJokoButton: `Return to Joko`,
            headerTitle: `And there you go!`,
            headerSubtitle: `Automatic savings on Safari are yours`,
        },
        missingAuthorization: {
            allWebsitesBeforeRetry: {
                title: `Missing authorizations`,
                subtitle: `You need to authorize the Joko extension "always" and on "all websites" in order to activate your cashback`,
                button: `CORRECT`,
            },
            allWebsitesAfterRetry: {
                title: `Failed authorization`,
                subtitle: `There seems to be a problem with the Joko authorization. You can correct this from your device Safari settings.`,
            },
            default: {
                title: `Joko is not activated`,
                subtitle: `You won't be notified when there is cash back to earn`,
                button: `FINISH ACTIVATION`,
                finishLaterButton: `Later`,
            },
            headerTitle: `Oops, you didn't give all the permissions`,
            headerSubtitle: `This is essential to allow Joko to work on Safari on all partner sites\n*Your privacy is protected*`,
            button: `Access settings`,
        },
        wrongBrowser: {
            title: `The Joko extension is not available on this browser`,
            subtitle: `The Joko extension is only available on Safari for now`,
        },
    },
    mobileSafariExtensionInstallationPagesRevamped: {
        instructionsClickSafariSettings: {
            part1: `Press the`,
            part2: `icon in the address bar`,
        },
        activateStep: {
            preamble: {
                tosConsent: `I accept the *terms of service* and *privacy policy*`,
                startButton: `Let's go!`,
            },
            instructionsManageExtensions: {
                part1: `Select`,
                part2: `Manage extensions`,
            },
            instructionsActivateJokoExtension: `Press the Joko extension`,
            headerTitle: `Activate Joko on Safari`,
        },
        authorizeStep: {
            instructionsClickBanner: {
                part1: `Press `,
                part2: `"Review"`,
                part3: `at the top right ${FINGER_POINTING_UP_EMOJI}`,
            },
            instructionsClickExtensionWarningIcon: {
                part1: 'Press',
                part2: 'next to Joko',
            },
            instructionsAlwaysAllow: {
                part1: 'Select',
                part2: 'Always allow',
            },
            instructionsAllowOnAllWebsites: {
                part1: 'Select',
                part2: 'Always allow on every website',
            },
            missingBannerButton: `I don't see a "Review" button`,
            headerTitle: `Authorize Joko on all websites`,
            headerSubtitle: `To unlock cashback and coupon codes\n*We respect your privacy*`,
        },
        missingAuthorization: {
            instructionsSystemSettingsClickButton: `Open your Settings`,
            instructionsSystemSettingsOtherWebsites: {
                part1: `Press`,
                part2: `Other websites`,
            },
            instructionsSystemSettingsAllow: {
                part1: `Press`,
                part2: `Allow`,
            },
            systemSettingsButton: `Open settings`,
            headerTitle: `You didn't give all the needed authorizations`,
            headerSubtitle: `Joko cannot work without all the authorizations\n*We respect your privacy*`,
        },
        conclusion: {
            returnToJokoButton: `Continue to Joko`,
            headerTitle: `And there you go!`,
            headerSubtitle: `Automatic savings on Safari are yours`,
        },
    },
};
